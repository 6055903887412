<template>
  <div>
    <div class="top-header" v-show="!sonDrawer">
      <i></i>
      <div class="top-title">
        主管理员设置
      </div>
    </div>
    <div class="admin-form" v-show="!op">
      <div>
        <div class="title-box">
          <div class="title-xian"></div>
          <div class="title-content">添加主管理员</div>
        </div>
        <el-form label-width="140px" :model="admin" ref="admin">
          <el-form-item label="企业名称：" prop="enterpriseName">
            <el-input v-model="admin.enterpriseName" size="medium" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="主管理员姓名：" prop="administratorName">
            <el-input v-model="admin.administratorName" size="medium"></el-input>
          </el-form-item>
          <el-form-item label="主管理员手机号：" prop="administratorPhone">
            <el-input v-model="admin.administratorPhone" size="medium"></el-input>
          </el-form-item>
        </el-form>
        <div class="form-btn">
          <el-button type="primary" @click="submitAdmin">确定</el-button>
        </div>
      </div>
    </div>
    <div class="admin-table" v-show="op">
      <div v-show="!sonDrawer">
        <div class="title-box">
          <div class="title-xian"></div>
          <div class="title-content">主管理员</div>
        </div>
        <div class="table-box">
          <el-table :data="adminData" border style="border-radius: 10px;border: 1px solid #CCCCCC;">
            <el-table-column label="企业名称" prop="ent_name" align="center"></el-table-column>
            <el-table-column label="管理员姓名" prop="p_mng_name" align="center"></el-table-column>
            <el-table-column label="管理员手机号" prop="p_mng_mobile" align="center"></el-table-column>
            <el-table-column label="管理员权限" prop="administratorJurisdiction" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
              <template>
                <el-button @click=" handleZhu() " type="text" size="mini" icon="el-icon-edit-outline" style="font-size: 20px;">
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div class="form-btn1">
          <el-button type="primary" @click="goBack">新增</el-button>
        </div> -->
      </div>
      <el-drawer title="" :visible.sync="drawer" :with-header="false" class="admin-operate">
        <div class="admin-operate-box">
          <div class="admin-operate-tltle">
            <i class="admin-i"></i>
            <span>主管理员修改</span>
          </div>
          <div class="admin-operate-boS">
            <div>
              <span>主管理员姓名：</span>
              <el-input size="small" v-model="name" :placeholder="nameName"></el-input>
            </div>
            <div>
              <span>主管理员手机号：</span>
              <el-input size="small" v-model="phone" :placeholder="phonePhone"></el-input>
            </div>
            <p>将以短信形式通知主管理员</p>
            <div class="admin-alter">
              <a class="admin-confirm" @click="confirm">确定</a>
              <a class="admin-cancel" @click="cancel">取消</a>
            </div>
          </div>
        </div>
      </el-drawer>

      <!-- 子管理员 -->
      <div class="AdminS-box">
        <p class="AdminS-p"><i></i><span>子管理员设置</span></p>
        <div class="AdminS">
          <div class="AdminS-title">
            <el-button @click="add_drawer" icon="el-icon-user" class="AdminS-button-add">
              <span>添加子管理员</span>
            </el-button>
            <el-button @click="del_ziGuanLiYuan" class="AdminS-button-del" icon="el-icon-delete">
              <span>批量删除</span>
            </el-button>
          </div>
          <div class="AdminS-drawer">
            <el-drawer title="我是标题" :visible.sync="sonDrawer" :with-header="false" size="480px">
              <div class="AdminS-drawer-title">
                <span></span>
                {{XinORbian}}子管理员
              </div>
              <div class="AdminS-drawer-jurisdiction">
                <p>选择权限:</p>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                  <el-checkbox v-for="(item) in jurisdiction" :label="item.module_id" :key="item.module_id">
                    {{item.title}}</el-checkbox>
                </el-checkbox-group>
              </div>
              <!-- 选择人员 -->
              <div class="AdminS-drawer-personnel">
                <span>选择人员:</span>
                <el-cascader v-if="XinORbian == '新增'" :options="treeData" :props="props" v-model="selectValue" :show-all-levels="false" @change="handleCCC">
                </el-cascader>
                <el-cascader v-else :options="treeData" disabled :props="propss" v-model="selectValue" :show-all-levels="false" @change="handleCCC">
                </el-cascader>
              </div>
              <div class="admin-alter">
                <!-- 子管理员 侧边栏的确认按钮 -->
                <a class="admin-confirm" @click="ConfirmSubmit">确定</a>
                <a class="admin-cancel" @click="cancelSubmit">取消</a>
              </div>
            </el-drawer>
          </div>
          <div class="AdminS-table">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width:100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="100">
              </el-table-column>
              <el-table-column prop="staff_name" label="姓名">
              </el-table-column>
              <el-table-column prop="name" label="权限">
              </el-table-column>
              <el-table-column prop="alter" label="操作" show-overflow-tooltip>
                <template scope="scope">
                  <!-- <div @click="detailData(scope.row)">{{ scope.row.acount }}</div>  -->
                  <img src="../../../assets/img/xiu.png" alt="" style="width: 23px;height: 24px" @click="SonAdministration_bianji(scope.row)">
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// /query_ent_primary_manager // 查询主管理员 // /set_ent_primary_manager // 设置主管理员  // /update_ent_primary_manager // 修改主管理员
// /query_ent_sub_manager_list_by_module // 查询子管理员列表 模块--多个子管理员 // /get_dept_list // 获取部门列表 // /query_all_staff_list_of_dept  // 查询部门及多级子部门下的所有人员列表
// /query_dept_staff_tree_aim_not //组织结构树，既有部门，又有人员 tree,不带目标 // /query_user_info // 用户身份信息：主管理员，子管理员，主管等// /query_ent_sub_manager_list // 查询子管理员列表
// /query_all_module_read_power_detail // 全模块下的权限明细 // /set_ent_sub_manager// 设置子管理员 --批量 // /delete_ent_sub_manager // 删除子管理员 --批量
import {
  set_ent_primary_manager,
  query_ent_primary_manager,
  update_ent_primary_manager,
  query_ent_sub_manager_list_by_module,
  get_dept_list,
  query_all_staff_list_of_dept,
  query_dept_staff_tree_aim_not,
  query_user_info,
  query_ent_sub_manager_list,
  query_all_module_read_power_detail,
  set_ent_sub_manager,
  delete_ent_sub_manager,
  query_ent_staff_list,
  query_dept_tree,
} from '../../../api/foodqiye'

import { toggle_user_ent } from '../../../api/pulic'

export default {
  data () {
    return {
      admin: {
        enterpriseName: null,
        administratorName: null,
        administratorPhone: null
      },

      op: false,
      drawer: false,
      name: '',
      phone: '',
      nameName: '',
      phonePhone: '',
      // 主管理员的信息
      adminData: [],
      // ----------子管理员信息----------
      // 侧边栏显示信息是新增还是编辑
      XinORbian: '',
      sonDrawer: false,
      // 存放子管理员的数据
      tableData: [
      ],
      // 选中的子管理员数据
      multipleSelection: [],
      // slect 下拉树选择框的多选框
      selectValue: [], // 关联样式
      NewselectValue: [], // 存放数据
      // 子管理员的 侧边栏
      accordion: true,
      props: {
        multiple: true,
        value: "id", // ID字段名
        label: 'label', // 显示名称
        children: 'children' // 子级字段名
      },
      propss: {
        value: "id", // ID字段名
        label: 'label', // 显示名称
        children: 'children' // 子级字段名
      },
      checkedId: "",
      treeData: [],
      // 存放选中树形节点的id
      checkedTreeid: [],
      // 权限设置数值

      jurisdiction: [{
        module_id: '1',
        title: '基本信息设置'
      },
      {
        module_id: '2',
        title: '功能权限设置'
      },
      {
        module_id: '3',
        title: '目标设置'
      },
      {
        module_id: '4',
        title: '营销设置'
      },
      {
        module_id: '5',
        title: '采购管理'
      },
      {
        module_id: '6',
        title: '仓库管理'
      },
      {
        module_id: '7',
        title: '菜品管理'
      },
      {
        module_id: '8',
        title: '桌台管理'
      },
      {
        module_id: '9',
        title: '人事管理'
      },
      {
        module_id: '10',
        title: '财务管理'
      },
      {
        module_id: '11',
        title: '审批设置'
      },
      {
        module_id: '12',
        title: '激励币'
      },
      {
        module_id: '13',
        title: '公共表单'
      },
      ],

      module_idAll: '',
      // 多选按钮 的选择
      checkedCities: [],
      // 存放需要删除的子管理员id
      del_Sub_administrator_id: [],
      // 点击编辑显示当前的人员
      default_checked_keys: [],
      // 判断是否第一次设置主管理员
      ShiFouFirst: false,
      list_let: ''
    }
  },


  created () {
    this.QueryMasterAdministrator()
    let a = this.$route.params.isShow
    let c = this.$route.params.isShows
    this.op = c
    this.op = a

    console.log(a, c, '状态 路由');
    //
    //
  },
  mounted () {
    // let isZhu = sessionStorage.getItem('isZhu')
    // if (isZhu == true) {
    //   this.op = true
    // }
    // var curPages =  getCurrentPages();


    // setTimeout(() => {
    //   sessionStorage.removeItem('isZhu')
    // }, 10000);
    if (this.$route.params.ent_info.ent_id == undefined) {
      var ent_id = sessionStorage.getItem('ent_info')
    }
    else {
      var ent_id = this.$route.params.ent_info.ent_id
    }
    query_dept_tree({
      data: {
        ent_id: ent_id
      }
    }).then((res) => {
      console.log('999999999999999999', res);
    })
    query_ent_sub_manager_list_by_module({
      data: {
        ent_id: ent_id
      }
    }).then((res) => {
      console.log('查询子管理员列表 模块--多个子管理员', res);
    })
    get_dept_list({
      data: {
        ent_id: ent_id
      }
    }).then((res) => {
      console.log('获取部门列表', res);
    })
    query_all_staff_list_of_dept({
      data: {
        ent_id: ent_id
      }
    }).then((res) => {
      console.log('查询部门及多级子部门下的所有人员列表', res);
    })
    query_user_info({
      data: {
        ent_id: ent_id,
        user_id: sessionStorage.getItem('user_id')
      }
    }).then((res) => {
      console.log('用户身份信息：主管理员，子管理员，主管等', res);
    })

    query_all_module_read_power_detail({
      data: {
        ent_id: ent_id
      }
    }).then((res) => {
      console.log('全模块下的权限明细 ', res);
    })

    // 查询子管理员列表
    this.QuerySubManagement()
    //  // 查询部门员工树
    this.query_department_staff_tree()
  },
  watch: {
    NewselectValue (newVal, oldVal) {
      this.selectValue = [] // 清空后加载显示
      this.checkedTreeid = [] // 清空后加载显示
      newVal.forEach((item) => {
        this.selectValue.push(item.label)
        this.checkedTreeid.push(item.id)
      })
      
      console.log(this.selectValue, '0000000000000000000000000');
    },
    sonDrawer (newval, oldval) {
      console.log(newval, oldval);
      // 当侧边栏页面关闭后 清空树形图选中节点
      if (oldval) {
        // this.$refs.selectTree.setCheckedKeys([])
        this.selectValue = [] // 输入框清空
      }
    }
  },
  methods: {
    handleZhu () {
      this.drawer = true
      this.phone = this.adminData[0].p_mng_mobile
      this.name = this.adminData[0].p_mng_name
    },
    handleCCC (value) {

      // let arr1 = this.selectValue

      // for (var i = 0; i < arr1.length; i++) {
      //   for (var j = i + 1; j < arr1.length; j++) {
      //     if (arr1[i][1] == arr1[j][1]) {
      //       arr1.splice(j, 1);
      //       j--;
      //     }
      //   }
      // }
      // console.log(arr1,'888888888888888888888');
      // this.selectValue = arr1

      let arr = this.selectValue.flat(Infinity)
       
      let arr2 = []
      for (const i in arr) {
        if (arr[i][0] == 'Y') {
          // this.unique(arr[i][0])
          arr2.push(arr[i])
        }
      }

      // arr2 = this.unique(arr2)
      // arr2 = arr2.join(',')
      console.log(arr2, arr, '999999999999999999999999999999999');
    },
    unique (arr) {
      return Array.from(new Set(arr))
    },
    // 查询部门员工树
    query_department_staff_tree () {
      console.log('(sessionStorage.getItem(user_id))', sessionStorage.getItem('user_id'));
      if (this.$route.params.ent_info.ent_id == undefined) {
        var ent_id = sessionStorage.getItem('ent_info')
      }
      else {
        var ent_id = this.$route.params.ent_info.ent_id
      }
      query_dept_staff_tree_aim_not({
        data: {
          ent_id
        }
      }).then((res) => {
        console.log('组织结构树，既有部门，又有人员 tree,不带目标', res);
        this.treeData = res.body.data
      })
    },
    // 查询子管理员列表 的接口方法
    QuerySubManagement () {
      if (this.$route.params.ent_info.ent_id == undefined) {
        var ent_id = sessionStorage.getItem('ent_info')
      }
      else {
        var ent_id = this.$route.params.ent_info.ent_id
      }
      query_ent_sub_manager_list({
        data: {
          ent_id
        }
      }).then((res) => {
        if (res.code == 200) {
          const tableData = res.body.data
          this.tableData = tableData
          this.zhuan_quanxian(tableData)
        }
        console.log('查询子管理员列表 ', res);
      })
    },
    // 判断点击编辑的图片时
    bianjipanduan () {

    },
    // 主管理员侧边栏 确定按钮
    confirm () {
      let _this = this.phone
      let _thisN = this.name
      let _thisMO = this.phonePhone
      // const ent_id = this.$route.params.ent_info.ent_id
      if (this.$route.params.ent_info.ent_id == undefined) {
        var ent_id = sessionStorage.getItem('ent_info')
      }
      else {
        var ent_id = this.$route.params.ent_info.ent_id
      }
      //判断是否非空
      if (this.phone != '' && this.name != '') {
        //判断手机号是否正确
        if (/^1[3456789]\d{9}$/.test(this.phone)) {
          //查询企业的所有人员
          query_ent_staff_list({
            data: {
              ent_id,
              active: '1'
            }
          }).then(res => {
            var AllRenyuan = res.body.data
            let isboole = false
            for (const i in AllRenyuan) {
              if (AllRenyuan[i].mobile == _this) {
                isboole = true
              }
            }
            //如果是公司的人
            if (isboole) {
              //直接设置
              update_ent_primary_manager({
                data: {
                  ent_id,
                  mobile: _this,
                  name: _thisN,
                  mobile_old: _thisMO
                }
              }).then((res) => {
                console.log('修改主管理员接口成功', res);
                if (res.code == '10105') {
                  this.drawer = false // 关闭侧边栏
                  this.QueryMasterAdministrator() // 重新请求数据 刷新数据
                  this.$message({
                    message: '修改主管理员成功',
                    type: 'success'
                  })
                }
                else {
                  this.$message({
                    message: '修改主管理员失败',
                    type: 'warning'
                  })
                }
              })
            }
            else {
              this.$message({
                message: '该人员不在公司中，无法设置主管理员',
                type: 'warning'
              })
            }
          })
        }
        else {
          this.$message({
            message: '手机号码不正确，请重新输入',
            type: 'warning'
          })
        }
      }
      else {
        this.$message({
          message: '姓名或手机号码不能为空',
          type: 'warning'
        })
      }
      this.name = ''
      this.phone = ''
    },
    // 取消按钮
    cancel () {
      this.drawer = false
      this.name = ''
      this.phone = ''
    },
    // 确认添加主管理员
    submitAdmin () {
      // 设置主管理员 ent_id：企业id  mobile：电话  name：姓名
      // const ent_id = this.$route.params.ent_info.ent_id
      let ent_id = ''
      if (this.$route.params.ent_info.ent_id == undefined) {
        ent_id = sessionStorage.getItem('ent_info')
      }
      else {
        ent_id = this.$route.params.ent_info.ent_id
      }

      set_ent_primary_manager({
        data: {
          ent_id: ent_id,
          mobile: this.admin.administratorPhone,
          name: this.admin.administratorName
        }
      }).then((res) => {
        console.log('设置主管理员', res);
        if (res.code == '10087') {
            
          this.adminData = []

          let ent_id = ''
          if (this.$route.params.ent_info.ent_id == undefined) {
            ent_id = sessionStorage.getItem('ent_info')
          }
          else {
            ent_id = this.$route.params.ent_info.ent_id
          }
          console.log(this.$route.params, '555555555555')
          query_ent_primary_manager({
            data: {
              ent_id: ent_id
            }
          }).then((res) => {
            console.log('查询主管理员', res);
            // location.reload()
            this.admin.enterpriseName = res.body.data.ent_name
            if (res.body.data.p_mng_mobile) {
              // this.op = true
              // this.adminData = [res.body.data]
              // this.$set(this.adminData[0], 'administratorJurisdiction', '全部权限')

              // sessionStorage.setItem('ent_id',ent_id)
              // this.$router.go(0)
              this.$message({
                message: '添加企业主管理员成功',
                type: 'success'
              })


              let ent_id = ''
              if (this.$route.params.ent_info.ent_id == undefined) {
                ent_id = sessionStorage.getItem('ent_info')
              }
              else {
                ent_id = this.$route.params.ent_info.ent_id
              }


              toggle_user_ent({
                data: {
                  user_id: this.$store.state.user_id,
                  ent_id: ent_id
                }
              }).then(res => {

                this.$store.state.ent_id = ent_id
                this.$store.state.qyInfo = item
                this.get_cat_list()

                sessionStorage.setItem('ent_id', item.ent_id)
                sessionStorage.removeItem('isLogin')
                sessionStorage.removeItem('ent_info')

                setTimeout(() => {
                  this.$router.go(0)
                  this.$store.state.loading = false
                }, 1000)

                if (this.$route.path == '/Admin' || this.$route.path == '/AdminAccount' || this.$route.path == '/AdminForget') {
                  this.$router.push('/AdminAccount')
                }
              })


              this.$router.push({
                name: 'AdminAccount',
                params: {
                  type: 'zhu'
                }
              })
            } else {
              this.op = false
            }
          })

        } else if (res.code == "10088") {
          this.$message({
            message: '添加企业主管理员失败',
            type: 'error'
          })
        }
      })
    },
    // 查询主管理员
    QueryMasterAdministrator () {
      this.adminData = []
      let ent_id = ''
      if (this.$route.params.ent_info.ent_id == undefined) {
        ent_id = sessionStorage.getItem('ent_info')
      }
      else {
        ent_id = this.$route.params.ent_info.ent_id
      }
      console.log(this.$route.params, '555555555555')
      query_ent_primary_manager({
        data: {
          ent_id: ent_id
        }
      }).then((res) => {
        console.log('查询主管理员', res);
        // location.reload()
        this.admin.enterpriseName = res.body.data.ent_name
        if (res.body.data.p_mng_mobile) {
           toggle_user_ent({
                data: {
                  user_id: this.$store.state.user_id,
                  ent_id: ent_id
                }
              }).then(res => {
                  
                this.$store.state.ent_id = ent_id
                this.$store.state.qyInfo = item
                this.get_cat_list()

                sessionStorage.setItem('ent_id', item.ent_id)
                sessionStorage.removeItem('isLogin')
                sessionStorage.removeItem('ent_info')

                setTimeout(() => {
                  this.$router.go(0)
                  this.$store.state.loading = false
                }, 1000)

                if (this.$route.path == '/Admin' || this.$route.path == '/AdminAccount' || this.$route.path == '/AdminForget') {
                  this.$router.push('/AdminAccount')
                }
              })
          // // 
          // if ( sessionStorage.getItem('isZhu') ) {
          //   // 
          //   this.$router.push({
          //     name:'AdminAccount',
          //     params:{
          //       type:'zhu'
          //     }
          //   })
          // }else{
          //   
          this.op = true
          // }
          this.adminData = [res.body.data]
          this.$set(this.adminData[0], 'administratorJurisdiction', '全部权限')
        } else {
          //
          this.op = false
        }
      })
    },
    handleUpdate (row) { },
    goBack () {
      this.op = false
    },
    // 设置子管理员的事件
    // 子管理员行多选框的事件
    handleSelectionChange (val) {
      console.log('子管理员行多选框的事件行的==点击勾选返回选中行的信息 ', val);
      this.multipleSelection = val
      this.del_Sub_administrator_id = []
      val.forEach((item) => {
        this.del_Sub_administrator_id.push({
          staff_id: item.staff_id
        })
      })
      this.list_let = val
    },
    // 批量删除子管理员
    del_ziGuanLiYuan () {
      // const ent_id = this.$route.params.ent_info.ent_id
      let ent_id = ''
      if (this.$route.params.ent_info.ent_id == undefined) {
        ent_id = sessionStorage.getItem('ent_info')
      }
      else {
        ent_id = this.$route.params.ent_info.ent_id
      }
      if (this.list_let.length == 0) {
        this.$message({
          type: 'error',
          message: '请至少选择一个或多个子管理员进行删除！！！'
        });
      } else {

        this.$confirm('您确认删除这些子管理员', '确认删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delete_ent_sub_manager({
            data: {
              ent_id,
              list: this.del_Sub_administrator_id
            }
          }).then((res) => {
            if (res.code == 200) {
              this.QuerySubManagement()
              console.log('批量删除子管理员', res);
              this.$message({
                type: 'success',
                message: `删除成功`
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      }

    },
    // 点击出现添加子管理员列表侧边栏
    add_drawer () {
      this.sonDrawer = true
      this.XinORbian = '新增'
      this.checkedCities = [] // 清空权限
    },
    // 点击出现子管理员的信息编辑侧边栏
    SonAdministration_bianji (rowObj) {


      this.sonDrawer = true
      this.XinORbian = '编辑'
      // 点击编辑首先进行清空数据操作

      // 选择权限
      let xuanzhong_module_id = rowObj.module_id.split(',')
      this.checkedCities = xuanzhong_module_id

      console.log('rowObj', rowObj);
      // 点击子管理员操作按钮 设置默认选中 // 树形图当前的默认选中人员 进行清空
      this.default_checked_keys = []
      this.selectValue = [] // 输入框清空
      // 对树形控件进行清除 选中 操作
      // this.$refs.selectTree.setCheckedKeys([]);
      this.selectValue.push(rowObj.staff_name)
      this.default_checked_keys.push(rowObj.staff_id) // 添加选中的人员staff_id使前边的多选框进行勾选
      this.selectValue = rowObj.staff_id

    },
    // 子管理员的侧边栏 新增/编辑的确认按钮
    ConfirmSubmit () {

      this.sonDrawer = false
      // let self = this
      // const ent_id = self.$store.state.ent_id
      let ent_id = ''
      if (this.$route.params.ent_info.ent_id == undefined) {
        ent_id = sessionStorage.getItem('ent_info')
      }
      else {
        ent_id = this.$route.params.ent_info.ent_id
      }
      const staff_ids = this.checkedTreeid.join(',') // YG1613871832405usu6,YG1613818616855tpta,YH1588673972778
      let a = ''
      let arr2 = []
      if (this.selectValue instanceof Array) {
        a = this.selectValue[this.selectValue.length - 1]

        let arr = this.selectValue.flat(Infinity)

        if (this.selectValue) {

          for (const i in arr) {
            if (arr[i][0] == 'Y') {
              arr2.push(arr[i])
            }
          }
        }
      } else {
        arr2 = this.selectValue
      }

      // const staff_ids = 'YG1613871832405usu6,YG1613818616855tpta,YH1588673972778,YG1590134726523wgh2,YG1590134726535l3ab'
      // console.log('staff_idsstaff_idsstaff_ids',staff_ids);
      console.log(ent_id, '||', this.module_idAll, staff_ids, '------------------------------------------------');
      if (this.XinORbian == '新增') {
        set_ent_sub_manager({
          data: {
            ent_id: ent_id,
            staff_ids: arr2.join(','),
            module_ids: this.module_idAll
          }
        }).then((res) => {
          console.log('新增子管理员', res);
          if (res.code == 200) {
            // 添加数据成功后 重新刷新子管理员数据
            this.QuerySubManagement()
            this.$message({
              message: '添加子管理员成功',
              type: 'success'
            })
          }
        })
      } else if (this.XinORbian == '编辑') {


        // 选择权限
        // let xuanzhong_module_id = rowObj.module_id.split(',')
        // this.checkedCities = xuanzhong_module_id

        // console.log('rowObj', rowObj);
        // // 点击子管理员操作按钮 设置默认选中 // 树形图当前的默认选中人员 进行清空
        // this.default_checked_keys = []
        // this.selectValue = [] // 输入框清空
        // // 对树形控件进行清除 选中 操作
        // // this.$refs.selectTree.setCheckedKeys([]);
        // this.selectValue.push(rowObj.staff_name)
        // this.default_checked_keys.push(rowObj.staff_id) // 添加选中的人员staff_id使前边的多选框进行勾选

        let staff_ids = String(this.default_checked_keys)
        let module_ids = String(this.checkedCities)

        if (module_ids.length > 0) {
          set_ent_sub_manager({
            data: {
              ent_id: ent_id,
              staff_ids,
              module_ids,
            }
          }).then((res) => {
            console.log('编辑子管理员', res);
            if (res.code == 200) {
              // 添加数据成功后 重新刷新子管理员数据
              this.QuerySubManagement()
              this.$message({
                message: '编辑子管理员成功',
                type: 'success'
              })
            } else {
              this.QuerySubManagement()
            }
          })

        } else {
          this.Wuquanixan()
        }
      }
    },
    Wuquanixan () {
      // let self = this
      // const ent_id = self.$store.state.ent_id
      let ent_id = ''
      if (this.$route.params.ent_info.ent_id == undefined) {
        ent_id = sessionStorage.getItem('ent_info')
      }
      else {
        ent_id = this.$route.params.ent_info.ent_id
      }
      set_ent_sub_manager({
        data: {
          ent_id,
          staff_ids: String(this.default_checked_keys)
        }
      }).then((res) => {
        console.log('编辑子管理员', res);
        if (res.code == 200) {
          // 添加数据成功后 重新刷新子管理员数据
          this.QuerySubManagement()
          this.$message({
            message: '编辑子管理员成功',
            type: 'success'
          })
        } else {
          this.QuerySubManagement()
        }
      })
    },

    cancelSubmit () {
      this.sonDrawer = false
    },
    // 查询部门列表
    InquiryDepartment () { },
    // 树形图节点点击的回调  点击选中后实现多选框的选中
    handleNodeClick (data, node) {
      // 点击行即可让复选框勾选 点击字体选中 再次点击取消选中
      if (!(data.children)) {
        node.checked = !(node.checked)
      }
    },
    // 下拉树的 节点选中状态发生变化时的回调
    handleCheckChange (data, checked, indeterminate) {
      console.log(data, checked);
      if (checked && (!data.children)) {
        this.NewselectValue.push(data)

      } else {
        // 取消选中后 多选框取消
        const index = this.NewselectValue.findIndex(d => d.id === data.id);
        this.NewselectValue.splice(index, 1);
      }
      // cunfang
      console.log("操作后", this.NewselectValue)
    },
    // 点击字体触发
    // getCurrentSelectArray(data,node){
    // console.log('树形图选中节点====当前选中节点变化时触发的事件',data,node);
    // console.log('树形图选中节点====当前选中节点变化时触发的事件',this.$refs.selectTree.getCheckedNodes(true, false));
    // },
    // 子管理员权限的多选按钮
    handleCheckedCitiesChange (value) {
      console.log('多选的value值', value);
      let newvalue = value
      console.log(newvalue);
      // 转逗号分隔的字符串
      this.module_idAll = newvalue.join(',')
      console.log(this.module_idAll, '}}}}}', newvalue.join(','));
    },
    // 将字符串的数字转化为汉字类型的字符串
    zhuan_quanxian (quanxian) {
      // console.log('quanxian',quanxian);
      quanxian.forEach((item) => {
        // console.log('item.module_id',item.module_id);
        // 权限转换汉字前的的数字
        const module_idStr = item.module_id
        // 转化为权限汉字 将数字字符串转化为数组
        let module_idArr = module_idStr.split(",")
        // 存放数字转换成汉字的
        let QX_chinese_characters = []
        module_idArr.forEach((itema) => {
          if (itema == 1) {
            QX_chinese_characters.push('基本信息设置')
          }
          if (itema == 2) {
            QX_chinese_characters.push('功能权限设置')
          }
          if (itema == 3) {
            QX_chinese_characters.push('目标设置')
          }
          if (itema == 4) {
            QX_chinese_characters.push('营销设置')
          }
          if (itema == 5) {
            QX_chinese_characters.push('采购管理')
          }
          if (itema == 6) {
            QX_chinese_characters.push('仓库管理')
          }
          if (itema == 7) {
            QX_chinese_characters.push('菜品管理')
          }
          if (itema == 8) {
            QX_chinese_characters.push('桌台管理')
          }
          if (itema == 9) {
            QX_chinese_characters.push('人事管理')
          }
          if (itema == 10) {
            QX_chinese_characters.push('财务管理')
          }
          if (itema == 11) {
            QX_chinese_characters.push('审批设置')
          }
          if (itema == 12) {
            QX_chinese_characters.push('激励币')
          }
          if (itema == 13) {
            QX_chinese_characters.push('公共表单')
          }
          item.name = QX_chinese_characters.join(',')
        })
      })
    },
  }
}

</script>

<style scoped lang='scss'>
.el-main {
  // height: 900px;
  background: #f6f6fa;
  overflow-x: hidden;
}

.admin-operate {
  .admin-operate-box {
    padding: 21px 0 0 30px;
    box-sizing: border-box;

    .admin-operate-boS {
      margin-top: 60px;

      & > div {
        display: flex;
        margin-bottom: 40px;
      }

      & > p {
        font-size: 14px;
        margin-top: 60px;
        color: #999999;
        text-align: center;
      }

      span {
        font-size: 16px;
        color: #333333;
        width: 190px;
        margin: 14px 10px 0 0;
        text-align: right;
      }

      input {
        width: 280px;
        height: 49px;
        border: 2px solid #cccccc;
        border-radius: 10px;
      }
    }

    .admin-operate-tltle {
      display: flex;

      i {
        width: 20px;
        height: 32px;
      }

      .admin-i {
        width: 6px;
        height: 32px;
        background: linear-gradient(180deg, #fc4353, #ff5e41);
        border-radius: 3px;
        display: block;
        margin-right: 11px;
      }

      span {
        font-size: 18px;
        color: #333333;
        margin-top: 6px;
      }
    }

    .admin-alter {
      display: flex;
      justify-content: space-between;
      width: 400px;
      margin: 480px auto 0 auto;

      a {
        width: 110px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
        display: block;
        border-radius: 10px;
        text-align: center;

        &:hover {
          cursor: pointer;
        }
      }

      .admin-confirm {
        color: #fff;
        background: #fc4353;
        margin-right: 136px;
      }

      .admin-cancel {
        background: #dddddd;
        color: #666666;
      }
    }
  }
}

.admin-account .admin-box .admin-box-login i {
  top: 12px;
}

.el-main {
  background: #f6f6fa;
}

.top-header {
  width: 100%;
  height: 34px;
  display: flex;
  justify-self: start;
  padding-left: 20px;

  i {
    background: url('../../../assets/img/din.png') no-repeat;
    width: 23px;
    height: 30px;
    display: block;
    margin-right: 11px;
  }

  .top-title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 34px;
  }
}

.admin-form {
  padding-top: 29px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div:nth-child(1) {
    width: 95%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    background: #fefefe;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 23px;
    padding-bottom: 31px;

    .title-box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;

      .title-xian {
        width: 6px;
        height: 34px;
        background: linear-gradient(221deg, #fc4353, #ff5e41);
        border-radius: 3px;
      }

      .title-content {
        font-size: 20px;
        padding-left: 11px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 34px;
      }
    }

    .el-form {
      padding-top: 52px;
      padding-left: 88px;

      .el-input__inner {
        border-radius: 10px;
        border: 1px solid #cccccc;
        width: 450px;
        height: 44px;
      }
    }

    .form-btn {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.admin-table {
  padding-top: 29px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & > div:nth-child(1) {
    width: 95%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    background: #fefefe;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 23px;
    padding-bottom: 31px;
    padding-right: 20px;
  }

  .title-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title-xian {
      width: 6px;
      height: 34px;
      background: linear-gradient(221deg, #fc4353, #ff5e41);
      border-radius: 3px;
    }

    .title-content {
      font-size: 20px;
      padding-left: 11px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
    }
  }
}

.admin-table {
  .el-table {
    margin-top: 48px;
  }
}

.table-box {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.form-btn1 {
  padding-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.AdminS-box {
  width: 95%;

  .AdminS-p {
    font-size: 18px;
    color: #999999;
    display: flex;
    margin: 26px 0 30px 0;

    span {
      margin: 4px;
    }

    i {
      background: url('../../../assets/img/din.png') no-repeat;
      width: 23px;
      height: 30px;
      display: block;
      margin-right: 11px;
    }
  }

  .AdminS {
    width: 100%;
    color: #f6f6f3;
    background: #f2f6fc;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;

    .AdminS-title {
      display: flex;
      width: 100%;
      height: 80px;
      background: linear-gradient(180deg, #fc4353, #ff5e41);
      border-radius: 15px 15px 0px 0px;
      align-items: center;
      box-sizing: border-box;
    }

    .AdminS-drawer {
      .AdminS-drawer-title {
        padding: 27px 0 0 31px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        display: flex;
        justify-content: left;
        align-items: center;
        color: #333333;
        margin-bottom: 30px;

        span {
          display: inline-block;
          width: 6px;
          height: 32px;
          background: linear-gradient(180deg, #fc4353, #ff5e41);
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .AdminS-drawer-personnel {
        padding: 53px 0 0 44px;
        min-height: 280px;

        & > span {
          display: inline-block;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          //  margin-bottom: 20px;
          margin-right: 13px;
        }
      }

      .AdminS-drawer-jurisdiction {
        padding: 0 0 0 44px;

        p {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-bottom: 20px;
        }

        ul {
          padding: 0 0 0 88px;

          li {
            margin-bottom: 16px;
            display: flex;
            justify-content: left;
            align-items: center;

            span {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              display: inline-block;
              margin-left: 20px;
            }
          }
        }
      }
    }

    .AdminS-drawer-button {
      padding: 50px 0 0 83px;

      button {
        width: 110px;
        height: 50px;
        outline: none;
        border: none;
        border-radius: 10px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }

      .confirm {
        background: linear-gradient(200deg, #fc4353, #ff5e41);
        color: #fff;
        margin-right: 100px;
      }

      .cancel {
        color: #666666;
      }
    }

    .AdminS-table {
      width: 100%;
    }
  }
}

// 树形控件
.el-select-dropdown {
  .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    min-height: 200px;
    max-height: 360px;
    padding: 10px 0px;
    overflow: hidden;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f3f3f3;
  }

  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1aa094;
  }

  // .el-select-dropdown__item.selected{
  //   font-weight: normal;
  // }
  ul li >>> .el-tree .el-tree-node__content {
    height: auto;
    padding: 0 20px;
  }

  .el-tree-node__label {
    font-weight: normal;
  }

  .el-tree >>> .is-current .el-tree-node__label {
    color: #409eff;
    font-weight: 700;
  }

  .el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
    color: #606266;
    font-weight: normal;
  }
}

.AdminS-box .el-checkbox-group .el-checkbox {
  margin-bottom: 16px;
  display: flex;
  width: 200px;
  margin: 10px auto;
}
.AdminS-box .el-checkbox-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 300px;
}
</style>
